<template>
    <div class="order-search-container">
        <div class="order-search-container-top">
            <van-nav-bar title="订单管理" fixed/>
        </div>
        <div class="order-search-container-search">
            <van-search placeholder="搜索一下" :disabled="true" show-action @click="picker.search=true">
                <div slot="action" @click="addOrder">新增订单</div>
            </van-search>
        </div>
        <div class="order-search-container-list">
            <van-pull-refresh v-model="freshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="finishedText"
                        @load="onLoad"
                >
                    <van-swipe-cell v-for="(item ,index) in list" v-bind:key="index">
                        <template slot="left">
                            <van-button square type="primary" icon="my iconfont icon-chakan" text="查看"
                                        @click="detail(item)"/>
                        </template>
                        <van-cell :border="true" :value="item.userName" :label="item.companyName">
                            <template slot="title">
                                <van-tag round plain type="danger" v-if="item.billStatus==0">
                                    {{item.billStatusName}}
                                </van-tag>
                                <van-tag round plain type="primary" v-else-if="item.billStatus==1">
                                    {{item.billStatusName}}
                                </van-tag>
                                <van-tag round plain type="success" v-else-if="item.billStatus==7">
                                    {{item.billStatusName}}
                                </van-tag>
                                <van-tag round plain v-else-if="item.billStatus==10">
                                    {{item.billStatusName}}
                                </van-tag>
                                <van-tag round plain type="warning" v-else>
                                    {{item.billStatusName}}
                                </van-tag>
                                <span class="custom-title">{{item.billCode}}</span>
                            </template>
                        </van-cell>
                        <template slot="right">
                            <van-button square type="info" icon="success" text="审批" @click="onAudit(item)"
                                        v-if="item.billStatus == 0 && auth.indexOf('/sale/order/audit') >= 0"/>
                            <van-button square type="warning" icon="my iconfont icon-message" text="提醒审批"
                                        @click="onSendAudit(item)"
                                        v-if="item.billStatus == 0 && auth.indexOf('/sale/order/audit') < 0"/>
                            <van-button square type="primary" icon="edit" text="修改"
                                        @click="onEdit(item)" v-if="item.billStatus == 0"/>
                            <van-button square type="danger" icon="cross" text="取消" @click="cancel(item)"
                                        v-if="item.billStatus == 0 || item.billStatus == 1"/>
                        </template>
                    </van-swipe-cell>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="order-search-container-pagination" v-show="pages>0">
            <van-pagination
                    v-model="option.page"
                    :page-count="pages"
                    mode="simple"
                    @change="onLoad"
            />
        </div>
        <!-- 弹出区域 -->
        <div class="order-search-container-popup">
            <!-- 搜索 -->
            <van-popup v-model="picker.search" position="right" :round="true" :style="{width:'80%',height:'100%'}">
                <van-cell-group>
                    <van-field
                            v-model="option.billCode"
                            label="订单号"
                            placeholder="请输入订单号"
                            clearable
                    />
                    <van-field
                            v-model="option.company"
                            label="公司名称"
                            placeholder="请输入公司名称"
                            clearable
                    />
                    <van-field
                            v-model="option.sendDate"
                            label="配送日期"
                            placeholder="请选择配送日期"
                            @focus="picker.sendDate=true"
                            readonly
                    />
                    <van-field
                            v-model="option.billStatusName"
                            label="订单状态"
                            placeholder="请选择订单状态"
                            @focus="picker.billStatus=true"
                            readonly
                    />
                    <van-field
                            v-show="deptList.length > 2"
                            v-model="option.deptName"
                            label="所属部门"
                            placeholder="请选择所属部门"
                            @focus="picker.dept=true"
                            readonly
                    />
                    <van-field
                            v-show="deptList.length > 2"
                            v-model="option.userName"
                            label="销售员"
                            placeholder="请选择销售员"
                            @focus="onPopupUser"
                            readonly
                    />
                    <van-button type="info" icon="passed" size="large" @click="onSearch"
                                style="position:fixed;bottom:0;"
                                text="查询"/>
                </van-cell-group>
            </van-popup>
            <!-- 配送日期 -->
            <van-popup v-model="picker.sendDate" position="bottom" :style="{height:'50%'}">
                <van-datetime-picker
                        v-model="currentDate"
                        type="date"
                        cancel-button-text="清除"
                        @cancel="onClearSendDate"
                        @confirm="onChangeSendDate"
                />
            </van-popup>
            <!-- 订单状态 -->
            <van-popup v-model="picker.billStatus" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="订单状态"
                        :columns="billStatusList"
                        @confirm="onChangeBillStatus"
                        @cancel="picker.billStatus=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 所属部门 -->
            <van-popup v-model="picker.dept" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="所属部门"
                        :columns="deptList"
                        @confirm="onChangeDept"
                        @cancel="picker.dept=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 销售员 -->
            <van-popup v-model="picker.user" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="销售员"
                        :columns="userList"
                        @confirm="onChangeUser"
                        @cancel="picker.user=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 领导 -->
            <van-action-sheet
                    v-model="picker.leader"
                    :actions="leaderList"
                    cancel-text="取消"
                    @cancel="picker.leader=false"
                    @select="doSendAudit"
                    title="请选择提醒对象"
            />
            <!-- 订单详情 -->
            <van-popup v-model="picker.order" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}" get-container="#app">
                <orderDetail :param.sync="param" :key="param.billNo" @onClose="picker.order=false"
                             v-if="param.billNo > 0"/>
            </van-popup>
            <!-- 新增修改订单 -->
            <van-popup v-model="picker.addOrder" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}">
                <addOrder :param.sync="param" :key="param.billNo" @onClose="onAddOrder"/>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import orderDetail from './detail'
    import addOrder from './input'

    export default {
        name: "order-search",
        components: {
            orderDetail,
            addOrder,
        },
        data() {
            return {
                freshing: false,
                auth: this.$store.state.user.auth || [],
                list: [],
                billStatusList: [
                    {index: '', text: '全部'},
                    {index: 0, text: '未确认'},
                    {index: 1, text: '已确认'},
                    {index: 2, text: '已打印生产单'},
                    {index: 3, text: '已质检入库'},
                    {index: 4, text: '已上车'},
                    {index: 5, text: '已下车'},
                    {index: 6, text: '已出仓'},
                    {index: 7, text: '已完成'},
                    {index: 8, text: '部分退货'},
                    {index: 9, text: '全部退货'},
                    {index: 10, text: '已取消'},
                    {index: 11, text: '配送自购'},
                ],
                leaderList: [],
                deptList: [],
                userList: [],
                userListCache: [],
                loading: false,
                finished: true,
                finishedText: '',
                pages: 0,
                option: {
                    page: 1,
                    company: '',
                    billCode: '',
                    sendDate: '',
                    billStatus: '',
                    billStatusName: '全部',
                    deptId: 0,
                    deptName: '全部',
                    userId: 0,
                    userName: '全部',
                },
                picker: {
                    search: false,
                    dept: false,
                    user: false,
                    order: false,
                    billStatus: false,
                    sendDate: false,
                    addOrder: false,
                    leader: false,
                },
                param: {billNo: 0},
                currentDate: new Date(),
                auditOrderNo: 0,
                auditLeaderId: ''
            }
        },
        mounted: function () {
            this.init()
        },
        methods: {
            init() {
                this.option.sendDate = this.nowDate(new Date());
                this.loadDept();
                this.onLoad();
            },
            loadDept() {
                let that = this;
                if (that.deptList.length == 0) {
                    that.$api.user.loadDept().then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            res.data.unshift({index: 0, text: '全部'});
                            that.deptList = res.data;
                        }
                    });
                }
            },
            onClearSendDate() {
                this.option.sendDate = '';
                this.picker.sendDate = false;
            },
            onChangeSendDate(value) {
                this.option.sendDate = this.nowDate(value);
                this.picker.sendDate = false;
            },
            onChangeBillStatus(value) {
                this.picker.billStatus = false;
                this.option.billStatus = value.index;
                this.option.billStatusName = value.text;
            },
            onChangeDept(value) {
                // 清空已选销售员
                if (this.option.deptId !== value.index) {
                    this.option.userId = 0;
                    this.option.userName = '全部';
                }
                this.picker.dept = false;
                this.option.deptId = value.index;
                this.option.deptName = value.text;
            },
            onPopupUser() {
                if (this.option.deptId == 0)
                    return this.$toast.fail('请选择所属部门');
                let that = this;
                let cache = [];
                if (this.userListCache.length > 0) {
                    this.userListCache.forEach(function (v) {
                        if (v.deptId == that.option.deptId) {
                            cache = v.list;
                        }
                    })
                }
                if (cache.length == 0) {
                    that.$api.user.loadUserByDept(that.option.deptId).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            res.data.unshift({index: 0, text: '全部'});
                            that.userListCache.push({deptId: that.option.deptId, list: res.data});
                            that.userList = res.data;
                            that.picker.user = true;
                        } else {
                            that.$toast(res.msg);
                        }
                    })
                } else {
                    this.userList = cache;
                    this.picker.user = true;
                }
            },
            onChangeUser(value) {
                this.picker.user = false;
                this.option.userId = value.index;
                this.option.userName = value.text;
            },
            onSearch() {
                this.option.page = 1;
                this.onLoad()
            },
            onRefresh() {
                this.option.page = 1;
                this.freshing = false;
                this.onLoad();
            },
            onLoad() {
                let that = this;
                that.picker.search = false;
                that.list = [];
                that.loading = true;
                that.finished = false;
                that.$api.foretaste.search(that.option).then(response => {
                    that.loading = false;
                    that.finished = true;
                    let res = response.data;
                    if (res.code == 1) {
                        if (res.data.list.length == 0) {
                            that.finishedText = '暂无数据'
                        } else {
                            that.finishedText = ''
                        }
                        that.pages = res.data.pages;
                        that.list = res.data.list;
                    } else {
                        that.$toast(res.msg);
                    }
                })
            },
            detail(item) {
                this.param.billNo = item.billNo;
                this.picker.order = true;
            },
            addOrder() {
                this.picker.addOrder = true;
            },
            onAddOrder() {
                this.picker.addOrder = false;
                this.onSearch();
            },
            // 修改订单
            onEdit(item) {
                this.param.billNo = item.billNo;
                return this.$toast('请取消后重新下单');
                //this.picker.addOrder = true;
            },
            onAudit(item) {
                let that = this;
                that.$dialog.confirm({
                    message: '确认审批吗？'
                }).then(() => {
                    that.$api.foretaste.audit(item).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.$toast.success(res.msg);
                            that.onLoad();
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                }).catch(() => {
                    // on cancel
                });
            },
            onSendAudit(item) {
                let that = this;
                that.leaderList = [];
                that.auditOrder = item.billNo;
                that.$api.user.loadLeader(item.userId).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.leaderList = res.data;
                        that.picker.leader = true;
                    } else {
                        that.$dialog.alert({
                            message: res.msg
                        });
                    }
                });
            },
            doSendAudit(item) {
                let that = this;
                that.auditLeaderId = item.id;
                that.$api.foretaste.sendAudit({billNo: that.auditOrder, userId: that.auditLeaderId}).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.$toast.success(res.msg);
                        that.picker.leader = false;
                    } else {
                        that.$dialog.alert({
                            message: res.msg
                        });
                    }
                });
            },
            cancel(item) {
                let that = this;
                that.$dialog.confirm({
                    message: '操作不可逆，确认取消吗？'
                }).then(() => {
                    that.$api.foretaste.cancel(item).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.$toast.success(res.msg);
                            that.onLoad();
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                }).catch(() => {
                    // on cancel
                });
            },
            nowDate(now) {
                let date = now ? now : new Date();
                let seperator = '-';
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = '0' + month;
                }
                if (day >= 0 && day <= 9) {
                    day = '0' + day;
                }
                return year + seperator + month + seperator + day;
            }
        }
    }
</script>

<style scoped>
    .order-search-container {
        padding-top: 46px;
    }

    .van-cell__title {
        flex: 2
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }

    .order-search-container-top {
        position: fixed;
        width: 100%;
        height: 46px;
        top: 0;
    }

    .order-search-container-list {
        position: fixed;
        width: 100%;
        top: 100px;
        bottom: 40px;
        overflow-y: scroll;
    }

    .order-search-container-pagination {
        position: fixed;
        width: 100%;
        height: 40px;
        bottom: 0;
    }
</style>
